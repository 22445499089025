// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  otp: (mobile: string) => path(ROOTS_AUTH, `/otp/${mobile}`),
  mpin: path(ROOTS_AUTH, "/mpin"),

  verifyMfa: path(ROOTS_AUTH, "/verify-mfa"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,

  //Dashboard Paths
  analytics: path(ROOTS_DASHBOARD, "/analytics"),
  daanGold: { root: path(ROOTS_DASHBOARD, "/daan-gold") },
  upload: {
    root: path(ROOTS_DASHBOARD, "/upload"),
    history: path(ROOTS_DASHBOARD, "/upload/history"),
    appointment: path(ROOTS_DASHBOARD, "/upload/appointment"),
    bookAppointment: path(ROOTS_DASHBOARD, "/upload/appointment/book"),
    reScheduleAppointment: (id: string) =>
      path(ROOTS_DASHBOARD, `/upload/appointment/book/${id}/reschedule`),
  },

  sellGold: {
    root: path(ROOTS_DASHBOARD, "/sell-gold"),
    requestSell: path(ROOTS_DASHBOARD, "/sell-gold/request-sell"),
  },
  releaseGold: {
    root: path(ROOTS_DASHBOARD, "/release-gold"),
    requestRelease: path(ROOTS_DASHBOARD, "/release-gold/request-release"),
  },
  approval: {
    root: path(ROOTS_DASHBOARD, "/approval"),
    detail: (id: string) => path(ROOTS_DASHBOARD, `/approval/${id}/details`),
  },
  transactions: { root: path(ROOTS_DASHBOARD, "/transactions") },

  mySecurity: { root: path(ROOTS_DASHBOARD, "/my-security") },

  statements: { root: path(ROOTS_DASHBOARD, "/statements") },

  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),

  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
};

export const PATH_DOCS = "";
