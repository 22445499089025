import { ReactNode, createContext, useEffect, useState } from 'react';
import { axiosInstance } from 'src/utils';

const initialState = {
  goldSellPrice: 0,
  goldBuyPrice: 0,
  changePercentage: 0,
  dollarPrice: 0,
  lbmaPrice: 0,
};

const SocketContext = createContext(initialState);

type IProps = {
  children: ReactNode;
};

const SocketProvider = ({ children }: IProps) => {
  const [goldRates, setGoldRates] = useState(initialState);

  const interval = 20000;

  const getRates = async () => {
    try {
      const { data } = await axiosInstance.get(`/customer/statics/gold-price`);

      setGoldRates({
        goldBuyPrice: data.data.buyPrice,
        changePercentage: data.data.changePercentage,
        dollarPrice: data.data.dollarPrice,
        lbmaPrice: data.data.lbmaPrice,
        goldSellPrice: data.data.sellPrice,
      });
    } catch (err) {
      console.log('Error fetching gold rates:', err);
    }
  };

  useEffect(() => {
    getRates();

    const intervalId = setInterval(getRates, interval);

    return () => clearInterval(intervalId);
  }, [interval]);

  return (
    <SocketContext.Provider value={goldRates}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
