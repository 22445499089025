import { Suspense, lazy, ElementType } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";
import { PermissionGuard } from "src/guards/PermissionGuard";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes("/dashboard") && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "otp/:mobile",
          element: (
            <GuestGuard>
              <Otp />
            </GuestGuard>
          ),
        },
        {
          path: "mpin",
          element: (
            <GuestGuard>
              <Mpin />
            </GuestGuard>
          ),
        },
        {
          path: "mpin/:verify",
          element: (
            <GuestGuard>
              <Mpin />
            </GuestGuard>
          ),
        },
        {
          path: "verify-mfa",
          element: (
            <GuestGuard>
              <VerifyMfa />
            </GuestGuard>
          ),
        },

        //sidebar

        { path: "login-unprotected", element: <Login /> },
      ],
    },

    // Dashboard Routes

    { path: "", element: <Navigate to={PATH_AFTER_LOGIN} replace /> },

    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={PATH_AFTER_LOGIN} replace />,
          index: true,
        },
        { path: "analytics", element: <GeneralApp /> },

        { path: "daan-gold", element: <DaanGold /> },

        {
          path: "upload",
          children: [
            {
              element: <Navigate to="/dashboard/upload/history" replace />,
              index: true,
            },
            { path: "history", element: <UploadHistory /> },
            { path: "appointment", element: <Appointment /> },
            { path: "appointment/book", element: <BookAppointment /> },
            {
              path: "appointment/book/:id/reschedule",
              element: <BookAppointment />,
            },
          ],
        },

        {
          path: "sell-gold",
          children: [
            { element: <SellGold />, index: true },

            { path: "request-sell", element: <RequestSell /> },
          ],
        },

        {
          path: "release-gold",
          children: [
            { element: <ReleaseGold />, index: true },

            { path: "request-release", element: <RequestRelease /> },
          ],
        },

        {
          path: "approval",
          children: [
            { element: <Approval />, index: true },
            { path: ":id/details", element: <ApprovalDetails /> },
          ],
        },

        { path: "transactions", element: <Transactions /> },

        { path: "my-security", element: <MySecurity /> },

        { path: "statements", element: <Statements /> },

        // -----------------------------------------------------------------------

        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: "account", element: <UserAccount /> },
          ],
        },

        { path: "permission-denied", element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "maintenance", element: <Maintenance /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Otp = Loadable(lazy(() => import("../pages/auth/Otp")));
const Mpin = Loadable(lazy(() => import("../pages/auth/mpin")));

const VerifyMfa = Loadable(lazy(() => import("../pages/auth/VerifyMfa")));

// GENERAL
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);

const DaanGold = Loadable(lazy(() => import("../pages/dashboard/daan-gold")));
const UploadHistory = Loadable(
  lazy(() => import("../pages/dashboard/upload-gold/history"))
);
const Appointment = Loadable(
  lazy(() => import("../pages/dashboard/upload-gold/appointment"))
);

const BookAppointment = Loadable(
  lazy(() => import("../pages/dashboard/upload-gold/book-appointment"))
);
const SellGold = Loadable(lazy(() => import("../pages/dashboard/sell-gold")));

const RequestSell = Loadable(
  lazy(() => import("../pages/dashboard/sell-gold/request-sell"))
);

const ReleaseGold = Loadable(
  lazy(() => import("../pages/dashboard/release-gold"))
);

const RequestRelease = Loadable(
  lazy(() => import("../pages/dashboard/release-gold/request-release"))
);

const Approval = Loadable(lazy(() => import("../pages/dashboard/approval")));

const ApprovalDetails = Loadable(
  lazy(() => import("../pages/dashboard/approval/detail"))
);
const Transactions = Loadable(
  lazy(() => import("../pages/dashboard/transactions"))
);

const MySecurity = Loadable(
  lazy(() => import("../pages/dashboard/my-security"))
);

const Statements = Loadable(
  lazy(() => import("../pages/dashboard/statements"))
);

// USER
const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAccount"))
);

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(
  lazy(() => import("../pages/dashboard/PermissionDenied"))
);

// MAIN
// const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const Page403 = Loadable(lazy(() => import("../pages/Page403")));
const Page404 = Loadable(lazy(() => import("../pages/Page404")));
