import jwtDecode from "jwt-decode";
// routes
import { PATH_AUTH } from "../routes/paths";
//
import axiosInstance from "./axiosInstance";

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp: number) => {
  let expiredTimer;

  const currentTime = Date.now();

  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert("Login Session expired");

    localStorage.removeItem("accessToken");

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode<{ exp: number }>(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem("accessToken");
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

const setTokenSession = (mfaToken: string | null) => {
  if (mfaToken) {
    // This function below will handle when token is expired
    const { exp } = jwtDecode<{ exp: number }>(mfaToken);
    handleTokenExpired(exp);
  }
};

const generateAuthUser = (data: any) => {
  const { user, trust } = data;

  const primaryBank = trust?.bankAccounts.find((bank: any) => bank.isPrimary);

  return {
    name: user?.name || "",
    email: user?.email || "",
    photoURL: user?.image || "",
    mobile: user?.mobile || "",
    org: { ...trust, primaryBank },
    ...user,
  };
};

export { isValidToken, setSession, generateAuthUser };
