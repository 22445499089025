const base = '/trust/auth';

const AuthApi = {
  base,
  login: base + '/login',
  varifyOtp: base + '/verify-otp',
  sendOtp: base + '/send-otp',
  createPin: base + '/create-pin',
  verifyPin: base + '/verify-pin',
};

export default AuthApi;
