import { v4 as uuid } from 'uuid';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  FormLabel,
  TextField,
  Typography,
} from '@mui/material';
//hooks
import useResponsive from 'src/hooks/useResponsive';
// components
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
// sections
import { LoginForm } from 'src/sections/auth/login';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { PATH_AUTH } from 'src/routes/paths';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { AuthApi, postApi } from 'src/common/apis';
import { SwipeableDrawer } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  background: 'url(/assets/login-bg.webp)',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  minHeight: '100vh',
  margin: 'auto',
  alignItems: 'center',
}));

// ----------------------------------------------------------------------

export default function Login() {
  const mdUp = useResponsive('up', 'md');

  const smUp = useResponsive('up', 'sm');

  const navigate = useNavigate();

  const [mobile, setMobile] = useState('');

  const handleLogin = () => {};

  const { handleSubmit, handleChange, values, errors, touched, isSubmitting } =
    useFormik({
      initialValues: { mobile: '' },
      onSubmit: async (values, { setSubmitting }) => {
        const res = await postApi({
          url: AuthApi.sendOtp,
          values: { mobile: String(values.mobile) },
          showToast: true,
        });
        setSubmitting(false);

        if (res.status < 400) {
          navigate(PATH_AUTH.otp(values.mobile));
        }
      },
    });
  const toggleDrawer = (newOpen: boolean) => () => {};

  return (
    <Page title="Admin Login">
      <RootStyle>
        <Container>
          <Card
            sx={{
              p: 2,
              width: mdUp ? '50%' : '90%',
              mx: 'auto',
              px: mdUp ? 10 : 3,
              py: mdUp ? 8 : 3,
            }}
          >
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  mb: 4,
                }}
              >
                <Box mb={2}>
                  <img src="/assets/login-logo.png" />
                </Box>
                <Typography textAlign={'center'} variant="h3">
                  Login
                </Typography>
                <FormLabel htmlFor="mobile" sx={{ mb: 2 }}>
                  Enter Registered Mobile Number
                </FormLabel>
                <TextField
                  onChange={handleChange}
                  value={values.mobile}
                  name="mobile"
                  label="Mobile"
                  id="mobile"
                  fullWidth
                  type="number"
                  error={touched.mobile && Boolean(errors.mobile)}
                  helperText={touched.mobile && errors.mobile}
                />
                <Typography color={'text.secondary'} variant="caption">
                  OTP will be send to your mobile number.
                </Typography>
                <LoadingButton
                  loading={isSubmitting}
                  type="submit"
                  variant="contained"
                  sx={{ borderRadius: 5, mt: 5 }}
                  fullWidth
                  disabled={String(values.mobile).length !== 10}
                >
                  Login
                </LoadingButton>{' '}
              </Box>
            </form>
          </Card>
        </Container>
      </RootStyle>
    </Page>
  );
}
