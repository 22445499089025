// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { Toaster } from 'react-hot-toast';
import './style.css';
import InstallPrompt from './InstallPrompt';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <NotistackProvider>
          <ProgressBarStyle />
          <ChartStyle />
          <ScrollToTop />
          <InstallPrompt />
          <Toaster
            position="top-center"
            toastOptions={{
              duration: 3000,
              success: {
                duration: 3000,
              },
            }}
          />
          <Router />
        </NotistackProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
