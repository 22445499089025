import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box, BoxProps } from "@mui/material";

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  height?: number | string;
  width?: number | string;
  disabledLink?: boolean;
  primary?: boolean;
}

export default function Logo({
  disabledLink = false,
  sx,
  width = 120,
  height = 30,
  primary = false,
}: Props) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = '/logo/logo_single.svg';

  const logo = (
    <Box sx={{ width: 120, height: 35, mb: 1, ...sx }}>
      <img src="/favicon/apple-touch-icon.png" height={60} width={65} alt="" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
