import { Box, Button, useTheme } from '@mui/material';
import React, { useEffect } from 'react';

const InstallPrompt: React.FC = () => {
  useEffect(() => {
    let deferredPrompt: any;

    const handleBeforeInstallPrompt = (event: any) => {
      event.preventDefault();

      deferredPrompt = event;

      showInstallButton();
    };

    const showInstallButton = () => {
      const installButton = document.getElementById(
        'install-button'
      ) as HTMLButtonElement;
      installButton.style.display = 'block';

      installButton.addEventListener('click', () => {
        deferredPrompt.prompt();

        deferredPrompt.userChoice.then((choiceResult: any) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
          } else {
            console.log('User dismissed the install prompt');
          }

          deferredPrompt = null;

          installButton.style.display = 'none';
        });
      });
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: '50%',
        right: '50%',
        zIndex: theme.zIndex.appBar + 2,
      }}
    >
      <Box
        id="install-button"
        style={{ display: 'none', zIndex: theme.zIndex.appBar + 3 }}
      >
        <Button> Install App</Button>
      </Box>
    </Box>
  );
};

export default InstallPrompt;
